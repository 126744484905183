/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.stream-view {
  color: white; }
  .stream-view .map-container {
    position: relative;
    background-color: #000000; }
    .stream-view .map-container .map-image {
      opacity: .4; }
    .stream-view .map-container canvas {
      position: absolute;
      width: 100%;
      z-index: 1; }
  .stream-view a {
    text-decoration: none; }
  .stream-view .btn.btn-primary {
    background: gray;
    color: white;
    margin: 20px 100px; }

.stream-team {
  display: flex; }

.stream-player {
  position: relative;
  background-color: black; }
  .stream-player--blue, .stream-player--gold {
    padding-right: 65px; }
    .stream-player--blue .stream-player__stats, .stream-player--gold .stream-player__stats {
      position: absolute;
      right: 20px;
      text-align: left; }
    .stream-player--blue .stream-player__berry,
    .stream-player--blue .stream-player__crown--2, .stream-player--gold .stream-player__berry,
    .stream-player--gold .stream-player__crown--2 {
      left: 35px; }
    .stream-player--blue .stream-player__sword,
    .stream-player--blue .stream-player__crown--1, .stream-player--gold .stream-player__sword,
    .stream-player--gold .stream-player__crown--1 {
      left: 58px; }
    .stream-player--blue .stream-player__speed,
    .stream-player--blue .stream-player__crown--0, .stream-player--gold .stream-player__speed,
    .stream-player--gold .stream-player__crown--0 {
      left: 81px; }
  .stream-player__icon {
    z-index: 0; }
  .stream-player__stats {
    z-index: 1;
    position: absolute;
    top: 3px;
    width: 42px;
    font-size: 32px;
    font-weight: bold; }
  .stream-player__kills {
    top: 3px;
    margin-bottom: 3px; }
  .stream-player__deaths {
    bottom: 7px; }
  .stream-player__berry, .stream-player__sword, .stream-player__speed, .stream-player__crown {
    z-index: 1;
    position: absolute;
    height: 16px;
    width: 16px; }
  .stream-player__berry, .stream-player__sword, .stream-player__speed {
    top: 5px; }
  .stream-player__crown {
    bottom: 10px; }

.berries-remaining {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 128px;
  width: 128px;
  background-color: #B300B7;
  border-radius: 50%;
  font-size: 64px;
  font-weight: bold; }
  .berries-remaining--gold {
    background-color: darkgoldenrod;
    height: 118px;
    width: 118px;
    border: 5px solid #B300B7; }
  .berries-remaining--blue {
    height: 118px;
    width: 118px;
    background-color: darkblue;
    border: 5px solid #B300B7; }

.match-timer {
  font-size: 64px; }

.stream-tournament-info {
  font-size: 64px;
  color: black; }

.App {
  display: grid;
  grid-template-areas: "head head" "content content";
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 70px 1fr;
  height: 100vh;
  background-color: #282c34;
  color: white; }
  .App a {
    color: #fff;
    text-decoration: none; }

header {
  grid-area: head;
  display: grid;
  grid-template-columns: 0.2fr 1fr 1fr;
  align-content: center;
  align-items: center;
  background-color: #781515;
  font-size: 20px; }
  header .logo {
    height: 50px;
    margin-right: 10px;
    margin-left: 10px; }

.c-hardware-status {
  width: auto;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #cbcbcb;
  background-color: #535353;
  font-size: 14px; }
  .c-hardware-status--unknown {
    background-color: #535353; }
  .c-hardware-status--offline {
    background-color: #d53434; }
  .c-hardware-status--online {
    background-color: #348b59; }
  .c-hardware-status__title, .c-hardware-status__status {
    display: inline-block; }

.page {
  max-width: 900px;
  padding: 10px; }

.sidebar {
  grid-area: sidebar;
  position: absolute;
  top: 70px;
  height: calc(100vh - 70px);
  width: 85%;
  margin-left: -85%;
  border-right: 1px solid black;
  overflow-y: scroll;
  -webkit-transition: margin-left 0.5s ease-out;
  transition: margin-left 0.5s ease-out;
  z-index: 3; }
  .sidebar.open {
    margin-left: 0;
    -webkit-transition: margin-left 0.5s ease-out;
    transition: margin-left 0.5s ease-out; }

.content {
  grid-area: content; }

.l-game-list {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  text-align: left;
  font-size: 16px; }

.c-game {
  padding: 10px;
  border-bottom: 1px solid gray;
  cursor: pointer;
  text-transform: capitalize;
  line-height: 20px; }
  .c-game--in-progress {
    background-color: #d2a608; }
    .c-game--in-progress.is-selected {
      background-color: #9d07da; }
  .c-game--completed {
    background-color: #676767; }
    .c-game--completed.is-selected {
      background-color: #9d07da; }

.gold {
  background-color: #d2a608; }

.blue {
  background-color: #504de2; }

.tabs,
.sidebar {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.game-details {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "tabs" "content";
  height: calc(100vh - 70px); }
  .game-details .tabs {
    grid-area: tabs;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 20px;
    background-color: #676767;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    cursor: pointer;
    text-align: center;
    text-transform: capitalize; }
    .game-details .tabs li {
      padding: 20px 0px;
      border-right: 1px solid black; }
      .game-details .tabs li.selected {
        background-color: #474747; }
  .game-details .info {
    padding: 10px;
    background-color: #676767;
    line-height: 20px;
    text-transform: capitalize; }
    .game-details .info.gold {
      background-color: #d2a608; }
    .game-details .info.blue {
      background-color: #504de2; }
  .game-details .stats {
    grid-area: content;
    display: grid;
    grid-row-gap: 10px;
    padding: 10px;
    overflow-y: scroll; }
    .game-details .stats .berries {
      text-align: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: #9d07da; }
      .game-details .stats .berries * {
        padding: 10px; }
      .game-details .stats .berries .header {
        grid-column: span 2; }
    .game-details .stats .players {
      display: grid;
      grid-row-gap: 10px; }
    .game-details .stats .player {
      display: grid;
      grid-template-columns: 1fr;
      text-align: center; }
      .game-details .stats .player .berries {
        background-color: inherit; }
        .game-details .stats .player .berries * {
          padding: 0px; }
      .game-details .stats .player > * {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-start: 1;
        grid-column-end: 6;
        padding: 10px;
        border: 1px solid black; }
      .game-details .stats .player .stats-block {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        padding: 0px;
        border-top: 1px solid black;
        border-left: 1px solid black;
        border-right: none;
        border-bottom: none; }
        .game-details .stats .player .stats-block > * {
          border-bottom: 1px solid black;
          border-right: 1px solid black;
          padding: 10px; }
        .game-details .stats .player .stats-block .header {
          grid-column: span 12; }
      .game-details .stats .player .uptime-stats .subheader {
        grid-column: span 4; }
      .game-details .stats .player .uptime-stats .value {
        grid-column: span 2; }
      .game-details .stats .player .gate-stats .subheader {
        grid-column: span 3; }
      .game-details .stats .player .gate-stats .value {
        grid-column: span 3; }
      .game-details .stats .player .berry-stats .subheader {
        grid-column: span 3; }
      .game-details .stats .player .berry-stats .value {
        grid-column: span 3; }
      .game-details .stats .player .snail-stats .subheader {
        grid-column: span 6; }
      .game-details .stats .player .snail-stats .value {
        grid-column: span 3; }
    .game-details .stats .maidens {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 10px;
      text-align: center;
      font-size: 14px; }
    .game-details .stats .maiden-uptime {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-template-areas: "header header header" "teams teams teams" "time time time" "percentage percentage percentage";
      background-color: #676767;
      text-transform: capitalize; }
      .game-details .stats .maiden-uptime * {
        padding: 10px; }
      .game-details .stats .maiden-uptime .header {
        grid-area: header;
        background-color: #676767; }
      .game-details .stats .maiden-uptime .gold {
        background-color: #d2a608; }
      .game-details .stats .maiden-uptime .blue {
        background-color: #504de2; }
  .game-details .graphs,
  .game-details .charts,
  .game-details .map,
  .game-details .awards {
    grid-area: content;
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    padding: 10px;
    overflow-y: scroll; }
  .game-details .map {
    grid-template-rows: auto;
    overflow-y: scroll; }
    .game-details .map .map-container {
      position: relative; }
      .game-details .map .map-container .map-image {
        opacity: 0.4; }
      .game-details .map .map-container canvas {
        position: absolute;
        width: 100%;
        z-index: 1; }
    .game-details .map .map-description {
      display: grid;
      grid-template-columns: 1fr 1fr;
      line-height: 20px;
      overflow-y: scroll;
      height: 100%; }
      .game-details .map .map-description div {
        grid-column: span 2; }
  .game-details .log {
    grid-area: content;
    overflow-y: scroll;
    text-align: left;
    font-size: 12px; }
  .game-details .log-filter {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    cursor: pointer;
    border-bottom: 1px solid black;
    border-left: 1px solid black; }
    .game-details .log-filter div {
      padding: 8px 5px;
      text-align: center;
      border-right: 1px solid black;
      border-top: 1px solid black;
      opacity: 0.4; }
      .game-details .log-filter div.selected {
        opacity: 1; }
      .game-details .log-filter div .gold {
        background-color: #d2a608; }
      .game-details .log-filter div .blue {
        background-color: #504de2; }
      .game-details .log-filter div img {
        height: 100%; }

.c-game-event {
  display: grid;
  grid-template-columns: 1fr 4fr;
  padding: 10px;
  background-color: #333333;
  line-height: 14px; }
  .c-game-event__time {
    color: #d265ff; }
  .c-game-event__data .gold {
    background-color: inherit;
    color: #e6b919; }
  .c-game-event__data .blue {
    background-color: inherit;
    color: #706ded; }
  .c-game-event__data .capitalize {
    text-transform: capitalize; }
  .c-game-event:nth-child(even) {
    background-color: #474747; }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #d2a608;
  border: 1px solid #504de2;
  border-radius: 5px;
  padding: 20px;
  cursor: pointer; }
  .btn:hover {
    background-color: #e6b919; }
  .btn a {
    color: #333333; }

.directory {
  padding: 20px; }
  .directory .title {
    margin-bottom: 20px; }
  .directory .btn {
    margin-bottom: 10px; }

.overall-stats {
  height: calc(100vh - 70px);
  overflow-y: scroll; }
  .overall-stats .header {
    margin-bottom: 5px; }
  .overall-stats .section {
    margin-bottom: 20px; }
  .overall-stats .details .section div {
    line-height: 20px; }

@media screen and (min-width: 768px) {
  .App {
    grid-template-areas: "head head" "sidebar content"; }
  .directory {
    max-width: 900px; }
    .directory .links {
      display: grid;
      grid-template-columns: repeat(5, 1fr); }
    .directory .btn {
      width: 70%; }
  .sidebar {
    position: static;
    position: initial;
    margin: 0;
    margin: initial;
    width: 100%; }
  header {
    display: flex;
    align-items: center; }
  .game-details .log .c-game-event {
    grid-template-columns: 80px auto; }
  .players {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 20px; } }

