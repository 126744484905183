.stream-view {
  color: white;

  .map-container {
    position: relative;
    background-color: #000000;

    .map-image {
      opacity: .4;
    }

    canvas {
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }

  a {
    text-decoration: none;
  }
  .btn.btn-primary {
    background: gray;
    color: white;
    margin: 20px 100px;
  }
}

.stream-team {
  display: flex;
}

.stream-player {
  $self: &;
  position: relative;
  background-color: black;

  // &--gold {
  //   padding-left: 65px;

  //   #{$self}__stats {
  //     position: absolute;
  //     left: 20px;
  //     text-align: right;
  //   }
  //   #{$self}__berry,
  //   #{$self}__crown--0 {
  //     right: 35px;
  //   }
  //   #{$self}__sword,
  //   #{$self}__crown--1 {
  //     right: 60px;
  //   }
  //   #{$self}__speed,
  //   #{$self}__crown--2 {
  //     right: 85px;
  //   }
  // }
  &--blue, &--gold {
    padding-right: 65px;

    #{$self}__stats {
      position: absolute;
      right: 20px;
      text-align: left;
    }
    #{$self}__berry,
    #{$self}__crown--2 {
      left: 35px;
    }
    #{$self}__sword,
    #{$self}__crown--1 {
      left: 58px;
    }
    #{$self}__speed,
    #{$self}__crown--0 {
      left: 81px;
    }
  }
  &__icon {
    z-index: 0;
  }
  &__stats {
    z-index: 1;
    position: absolute;
    top: 3px;
    width: 42px;
    font-size: 32px;
    font-weight: bold;
  }
  &__kills {
    top: 3px;
    margin-bottom: 3px;
  }
  &__deaths {
    bottom: 7px;
  }
  &__berry, &__sword, &__speed, &__crown {
    z-index: 1;
    position: absolute;
    height: 16px;
    width: 16px;
  }
  &__berry, &__sword, &__speed {
    top: 5px;
  }
  &__crown {
    bottom: 10px;
  }
}

.berries-remaining {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 128px;
  width: 128px;
  background-color: #B300B7;
  border-radius: 50%;
  font-size: 64px;
  font-weight: bold;

  &--gold {
    background-color: darkgoldenrod;
    height: 118px;
    width: 118px;
    border: 5px solid #B300B7;
  }
  &--blue {
    height: 118px;
    width: 118px;
    background-color: darkblue;
    border: 5px solid #B300B7;
  }
}

.match-timer {
  font-size: 64px;
}

.stream-tournament-info {
  font-size: 64px;
  color: black;
}