// div {
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   -ms-box-sizing: border-box;
//   -o-box-sizing: border-box;
//   box-sizing: border-box;
// }
$gold-lighter: #e6b919;
$gold-base: #d2a608;

$blue-lighter: #706ded;
$blue-base: #504de2;

$gray-base: #676767;
$gray-darker: #474747;
$gray-darkest: #333333;

$purple-lighter: #d265ff;
$purple-base: #9d07da;

$maroon-base: #781515;

.App {
  display: grid;
  grid-template-areas: "head head" "content content";
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 70px 1fr;
  height: 100vh;
  background-color: #282c34;
  color: white;

  a {
    color: #fff;
    text-decoration: none;
  }
}

header {
  grid-area: head;
  display: grid;
  grid-template-columns: 0.2fr 1fr 1fr;
  align-content: center;
  align-items: center;
  // background-color: #ffa07e;
  background-color: $maroon-base;
  font-size: 20px;

  .logo {
    height: 50px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.c-hardware-status {
  width: auto;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #cbcbcb;
  background-color: #535353;
  font-size: 14px;

  &--unknown {
    background-color: #535353;
  }
  &--offline {
    background-color: #d53434;
  }
  &--online {
    background-color: #348b59;
  }

  &__title,
  &__status {
    display: inline-block;
  }
}

.page {
  max-width: 900px;
  padding: 10px;
}

.sidebar {
  grid-area: sidebar;
  position: absolute;
  top: 70px;
  height: calc(100vh - 70px);
  width: 85%;
  margin-left: -85%;
  border-right: 1px solid black;
  overflow-y: scroll;
  transition: margin-left 0.5s ease-out;
  z-index: 3;

  &.open {
    margin-left: 0;
    transition: margin-left 0.5s ease-out;
  }
}

.content {
  grid-area: content;
}

.l-game-list {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  text-align: left;
  font-size: 16px;
}

.c-game {
  padding: 10px;
  border-bottom: 1px solid gray;
  cursor: pointer;
  text-transform: capitalize;

  line-height: 20px;

  &--in-progress {
    background-color: $gold-base;
    &.is-selected {
      background-color: $purple-base;
    }
  }
  &--completed {
    background-color: $gray-base;
    &.is-selected {
      background-color: $purple-base;
    }
  }
}

.gold {
  background-color: $gold-base;
}
.blue {
  background-color: $blue-base;
}

.tabs,
.sidebar {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.game-details {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "tabs" "content";
  height: calc(100vh - 70px);

  .tabs {
    grid-area: tabs;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 20px;
    background-color: $gray-base;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    cursor: pointer;
    text-align: center;
    text-transform: capitalize;

    li {
      padding: 20px 0px;
      border-right: 1px solid black;
      &.selected {
        background-color: $gray-darker;
      }
    }
  }

  .info {
    padding: 10px;
    background-color: $gray-base;
    line-height: 20px;
    text-transform: capitalize;

    &.gold {
      background-color: $gold-base;
    }
    &.blue {
      background-color: $blue-base;
    }
  }

  .stats {
    grid-area: content;
    display: grid;
    grid-row-gap: 10px;
    padding: 10px;
    overflow-y: scroll;

    .berries {
      text-align: center;
      * {
        padding: 10px;
      }
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: $purple-base;
      .header {
        grid-column: span 2;
      }
    }

    .players {
      display: grid;
      grid-row-gap: 10px;
    }

    .player {
      display: grid;
      grid-template-columns: 1fr;
      text-align: center;

      .berries {
        background-color: inherit;
        * {
          padding: 0px;
        }
      }

      // border-top: 1px solid black;
      // border-left: 1px solid black;
      // border-bottom: 1px solid black;

      & > * {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-start: 1;
        grid-column-end: 6;
        padding: 10px;
        border: 1px solid black;
      }

      .stats-block {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        padding: 0px;
        border-top: 1px solid black;
        border-left: 1px solid black;
        border-right: none;
        border-bottom: none;

        & > * {
          border-bottom: 1px solid black;
          border-right: 1px solid black;
          padding: 10px;
        }

        .header {
          grid-column: span 12;
        }
      }

      .uptime-stats {
        .subheader {
          grid-column: span 4;
        }
        .value {
          grid-column: span 2;
        }
      }

      .gate-stats {
        .subheader {
          grid-column: span 3;
        }
        .value {
          grid-column: span 3;
        }
      }

      .berry-stats {
        .subheader {
          grid-column: span 3;
        }
        .value {
          grid-column: span 3;
        }
      }

      .snail-stats {
        .subheader {
          grid-column: span 6;
        }
        .value {
          grid-column: span 3;
        }
      }
    }

    .maidens {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 10px;
      text-align: center;
      font-size: 14px;
    }

    .maiden-uptime {
      * {
        padding: 10px;
      }
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-template-areas:
        "header header header"
        "teams teams teams"
        "time time time"
        "percentage percentage percentage";

      background-color: #676767;
      text-transform: capitalize;

      .header {
        grid-area: header;
        background-color: $gray-base;
      }
      .gold {
        background-color: $gold-base;
      }
      .blue {
        background-color: $blue-base;
      }
    }
  }

  .graphs,
  .charts,
  .map,
  .awards {
    grid-area: content;
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    padding: 10px;
    overflow-y: scroll;
  }

  .map {
    grid-template-rows: auto;
    overflow-y: scroll;

    .map-container {
      position: relative;

      .map-image {
        opacity: 0.4;
      }

      canvas {
        position: absolute;
        width: 100%;
        z-index: 1;
      }
    }

    .map-description {
      display: grid;
      grid-template-columns: 1fr 1fr;
      line-height: 20px;
      overflow-y: scroll;
      height: 100%;

      div {
        grid-column: span 2;
      }
    }
  }

  .log {
    // display: none;
    grid-area: content;
    // padding: 10px 10px;
    overflow-y: scroll;
    text-align: left;
    font-size: 12px;
  }
  .log-filter {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    // background-color: $gold-lighter;
    cursor: pointer;
    border-bottom: 1px solid black;
    border-left: 1px solid black;

    div {
      padding: 8px 5px;
      text-align: center;
      border-right: 1px solid black;
      border-top: 1px solid black;

      opacity: 0.4;

      &.selected {
        opacity: 1;
      }
      .gold {
        background-color: $gold-base;
      }
      .blue {
        background-color: $blue-base;
      }

      img {
        height: 100%;
      }
    }
  }
}

.c-game-event {
  display: grid;
  grid-template-columns: 1fr 4fr;
  padding: 10px;
  background-color: $gray-darkest;
  line-height: 14px;

  &__time {
    // color: rgb(80, 151, 226);
    color: $purple-lighter;
  }
  &__data {
    // text-transform: capitalize;
    .gold {
      background-color: inherit;
      color: $gold-lighter;
    }
    .blue {
      background-color: inherit;
      color: $blue-lighter;
    }
    .capitalize {
      text-transform: capitalize;
    }
  }

  &:nth-child(even) {
    background-color: $gray-darker;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $gold-base;
  border: 1px solid $blue-base;
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;

  &:hover {
    background-color: $gold-lighter;
  }

  a {
    color: $gray-darkest;
  }
}

.directory {
  padding: 20px;

  .title {
    margin-bottom: 20px;
  }

  .btn {
    margin-bottom: 10px;
  }
}

.overall-stats {
  height: calc(100vh - 70px);
  overflow-y: scroll;

  .header {
    margin-bottom: 5px;
  }
  .section {
    margin-bottom: 20px;
  }
  .details .section {
    div {
      line-height: 20px;
    }
  }
}

@media screen and (min-width: 768px) {
  .App {
    grid-template-areas: "head head" "sidebar content";
  }

  .directory {
    max-width: 900px;

    .links {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }
    .btn {
      width: 70%;
    }
  }

  .sidebar {
    position: initial;
    margin: initial;
    width: 100%;
  }
  // REFACTOR
  header {
    display: flex;
    align-items: center;
  }
  .game-details {
    // grid-template-columns: 3fr 1fr;
    // grid-template-areas: "tabs game-log" "content game-log";
    .log {
      // display: block;
      // grid-template-columns: 50px, auto;
      .c-game-event {
        grid-template-columns: 80px auto;
      }
    }
  }
  .players {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 20px;
  }
}
